<template>
  <div id="index" ref="index">
    <section class="w-full min-h-screen flex justify-center items-center">
      <div class="container p-10 leading-relaxed">
        <div class="lg:w-10/12 mx-auto pages" v-html="text"></div>
      </div>
    </section>
    <Copy />
  </div>
</template>

<script>
import axios from "axios";
import Copy from "@/components/Theme/Footer";

export default {
  name: "Privacy",
  components: {
    Copy
  },
  data() {
    return {
      text: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await axios.get("/api/app/9").then(response => {
        this.text = response.data.text;
      });
    }
  }
};
</script>
